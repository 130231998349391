.cell{
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media print {
    .print-only {
      display: none!important;
    }
  }