.mic_animation{
    animation: mic 1000ms cubic-bezier(0.9, 0.7, 0.5, 0.9) infinite;
}
@keyframes mic { 
	25% {
        transform: scale(1.2)
    }
    50% {
        transform: scale(1.4)
    }
    75%{
        transform: scale(1.6)
    }
  }
.show-tag{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 24px;
    margin: 2px;
    line-height: 22px;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(232, 232, 232);
    border-radius: 5px;
    box-sizing: content-box;
    padding: 0px 8px 0px 8px;
    outline: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}  