.preview-container {
    display: flex;
    justify-content: center;
    margin-top: 14px;
  }
  
  .mobile-frame {
    background-color: #fafafa;
    border-radius: 8px;
    width: 312px;
    height: 500px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }
  
  .mobile-screen {
    background-color: #e5ddd5; /*#e5ddd5*/
    padding: 16px;
    background-image: url("../../assets/images/whatsapp_chat_wallpaper.png");
    height: 500px;
    overflow-y: auto;
  }
  
  .whatsapp-message {
    display: flex;
    margin-bottom: 16px;
  }
  
  /* .whatsapp-avatar {
    background-color: #f8f8f8;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 12px;
  } */
  
  .whatsapp-message-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .whatsapp-sender {
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .whatsapp-text {
    background-color: #f8f8f8;
    border-radius: 16px;
    font-size: 16px;
    padding: 12px;
    margin-bottom: 4px;
  }
  
  .whatsapp-timestamp {
    color: #999;
    font-size: 12px;
  }
  