.wrapper-class-name{
    background-color: #fbfbfb;
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    height: 450px !important;
}
.editor-class-name{
    height: 350px !important;
    border: 1x solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}