.recipient-avtar-selected{
    background-color : rgba(60,64,67,.6);
    border-radius: 100%;
}
.recipient-avtar-selection {
    display: none!important;
}
.recipient-avtar-selection-selected {
    position:absolute;
    display: block!important;
    height:40px;
    width:40px;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    padding: 5px;
    color:white!important;
    background-color : rgba(60,64,67,.6)!important;
    border-radius: 100%!important;
}
.recipient-avtar:hover .recipient-avtar-selection {
    position:absolute;
    background-color : rgba(60,64,67,.6)!important;
    display: block!important;
    border-radius: 100%!important;
    height:40px;
    width:40px;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    color:white!important;
    z-index: 1000;
    visibility: visible;
    padding: 5px;
}