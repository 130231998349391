.chat_blocked{
    pointer-events: none;
    opacity: 0.5;
}
.message_highlight {
    border-radius: 3px;
    animation: highlight 300000ms ease-out;
}
.cancel_icon{
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 9999;
}
.typing_indicator{
  font-size: 14px;
  position: absolute;
  top: 0;
  width:100%;
  text-align: center;
  color: #5d5dff;
  font-style: italic;
}
.selection_menu_item.Mui-selected{
  background-color: #1A73E8 !important;
color:white !important
}
.msg-attachment{
  position:relative;
  border-radius: 10px;
  border: solid 1px #dbcccc;
  margin: 6px 0 6px 0;
  justify-content: center;
  align-items: center;
}
@keyframes highlight {
    0% {
      background-color: yellow;
    }
    50% {
      background-color: rgb(221, 221, 173)
    }
    100% {
      background-color: white;
    }
  }