.sound_container {
    justify-content: flex-start;
    align-items: center;
    padding: 12px 12px 12px 0px;
    margin: 8px 8px 8px 0px;
}

.selectedStyle {
    background-color: #1876F2 !important;
    color: #fff !important;
    min-width: 120px !important;
    padding: 5px 8px !important;
}

.selectedStyle * {
    color: #fff !important;
}

.prefernce_m_list_item {
    min-width: 0px !important;
}