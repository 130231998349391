.message_highlight {
    border-radius: 14px;
    animation: highlight 3000ms ease-out;
}
/* Enable smooth scrolling for your container */
.smooth-scroll-container {
  scroll-behavior: smooth;
  height: 100%!important;
  width: 100%!important;
  padding-left: 6%;
  padding-right: 6%;
  /* Add other styles to the container as needed */
}
@keyframes highlight {
    0% {
      background-color: rgba(220, 220, 220, 0.951);
    }
    100% {
      background-color: white;
    }
}